<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Fluorine-18 is a radioisotope used to help detect cancer. The half-life of
        <chemical-latex content="^{18}F" />
        is
        <stemble-latex content="$1.83\,\text{hr.}$" />
        After the
        <chemical-latex content="^{18}F" />
        is created, physicians need to use it before the rate drops below
        <stemble-latex content="$13.7\%$" />
        of the original decay rate. How long (in hours) do the physicians have to use the
        <stemble-latex content="$^{18}\text{F?}$" />
      </p>

      <calculation-input
        v-model="inputs.time"
        prepend-text="$\text{t:}$"
        append-text="$\text{hr}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220A4Q8',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        time: null,
      },
    };
  },
};
</script>
